import defaultSettings from '$lib/data/defaultSettings.json';
import type {FullGojira, Gojira} from '$lib/gojiras';
import {areFullGojiraEqual, clone, defaultFullGojira} from '$lib/gojiras';
import {attr} from 'svelte/internal';
import {derived, get, writable} from 'svelte/store';
import {currentSettings} from './settings';

export type CurrentGojiraState = {
  inList: boolean;
  changed: boolean;
};

export type AllGojiras = {
  lastUsed: string;
  list: {[name: string]: FullGojira};
};

function save(all: AllGojiras): void {
  try {
    window.localStorage.setItem('gojiras', JSON.stringify(all));
  } catch (e) {
    console.error(e);
  }
}

function fetch(): AllGojiras {
  let fromLocalStorage: AllGojiras | undefined;
  if (typeof window !== 'undefined') {
    try {
      const str = window.localStorage.getItem('gojiras');
      fromLocalStorage = JSON.parse(str);
    } catch (e) {
      console.error(e);
    }
  }

  // console.log({fromLocalStorage});

  if (!fromLocalStorage || !fromLocalStorage.lastUsed) {
    fromLocalStorage = {
      lastUsed: defaultFullGojira.gojira.name,
      list: {
        [defaultFullGojira.gojira.name]: clone(defaultFullGojira),
      },
    };
  }

  // console.log({fromLocalStorage});
  return fromLocalStorage;
}

const all = fetch();

const $currentGojira = clone(all.list[all.lastUsed].gojira);
const currentGojira = writable<Gojira>($currentGojira);

const $allGojiras: AllGojiras = all;
const allGojirasStore = writable<AllGojiras>($allGojiras);
const allGojiras = {
  subscribe: allGojirasStore.subscribe,
  delete(name: string) {
    allGojirasStore.update((v) => {
      delete v[name];
      // TODO lastUsed ?
      save(v);
      return v;
    });
  },
  saveCurrent() {
    allGojirasStore.update((v) => {
      const cloned = clone(get(currentGojira));
      const settings = clone(get(currentSettings));
      // const existingSettings = v.list[cloned.name];
      // if (!existingSettings) {
      //   console.log(`adding ${cloned.name}....`);
      // } else {
      //   console.log(`edditing ${cloned.name}...`);
      // }
      v.list[cloned.name] = {
        gojira: cloned,
        settings,
      };
      v.lastUsed = cloned.name;
      save(v);
      return v;
    });
  },
  use(name: string) {
    const all = clone(get(allGojiras));
    currentGojira.update((v) => {
      return clone(all.list[name].gojira);
    });
    currentSettings.set(all.list[name].settings);
    allGojirasStore.update((v) => {
      v.lastUsed = name;
      save(v);
      return v;
    });
  },
  reset() {
    const state = clone(get(currentGojiraState));
    if (state.inList && state.changed) {
      const all = clone(get(allGojiras));
      const current = clone(get(currentGojira));
      currentGojira.update((v) => {
        return clone(all.list[current.name].gojira);
      });
    }
  },
  clear() {
    window.localStorage.removeItem('gojiras');
    allGojirasStore.set(fetch());
  },
};

const currentGojiraState = derived(
  [allGojiras, currentGojira, currentSettings],
  ([$allGojiras, $currentGojira, $currentSettings]) => {
    let valid = true;
    const existingGojira = $allGojiras.list[$currentGojira.name];
    const inList = !!existingGojira;
    let changed = false;
    if (existingGojira) {
      changed = !areFullGojiraEqual(existingGojira, {gojira: $currentGojira, settings: $currentSettings});
    }

    for (let attributeIndex = 0; attributeIndex < $currentGojira.attributes.length; attributeIndex++) {
      if (
        !$currentSettings.gojiraCardSets[attributeIndex] ||
        $currentSettings.gojiraCardSets[attributeIndex].set[$currentGojira.attributes[attributeIndex]] === undefined
      ) {
        valid = false;
        break;
      }
    }

    if (valid) {
      if ($currentSettings.baseCardSets[$currentGojira.base] === undefined) {
        valid = false;
      }
    }

    return {
      inList,
      changed,
      valid,
    };
  }
);

export {currentGojira, allGojiras, currentGojiraState};
